import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { DiaryType, DiaryTypeProps } from '../domain/diary_type/diary_type';

export interface DiaryTypeSchema {
  name: string;
  label: string;
  icon: string;
  hint: string;
  displayed: boolean;
  sorter: number;
}

@Injectable()
export class DiaryTypeRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collectionDefault(queryFn?: QueryFn) {
    return this.firestore.collection<DiaryTypeSchema>('diary_types', queryFn);
  }

  private collectionDietitian(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<DiaryTypeSchema>('diary_types', queryFn);
  }

  private collectionPatientGroup(groupId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patient_groups')
      .doc(groupId)
      .collection<DiaryTypeSchema>('diary_types', queryFn);
  }

  private collectionPatient(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<DiaryTypeSchema>('diary_types', queryFn);
  }

  public static toSchema(entity: DiaryType): DiaryTypeSchema {
    return <DiaryTypeSchema>{
      name: entity.name,
      label: entity.label,
      icon: entity.icon,
      hint: entity.hint,
      displayed: entity.displayed,
      sorter: entity.sorter,
    };
  }

  public static fromSchema(schema: DiaryTypeSchema, id?: string): DiaryType {
    return DiaryType.create(
      {
        name: schema.name,
        label: schema.label,
        icon: schema.icon,
        hint: schema.hint,
        displayed: schema.displayed,
        sorter: schema.sorter,
      } as DiaryTypeProps,
      new UniqueEntityID(id),
    );
  }

  async findAllDefault(): Promise<DiaryType[]> {
    const snap = await firstValueFrom(this.collectionDefault().get());
    return snap.docs.map((doc) =>
      DiaryTypeRepository.fromSchema(doc.data(), doc.id),
    );
  }

  async findAllDietitian(dietitianId: string): Promise<DiaryType[]> {
    const snap = await firstValueFrom(
      this.collectionDietitian(dietitianId).get(),
    );
    return snap.docs.map((doc) =>
      DiaryTypeRepository.fromSchema(doc.data(), doc.id),
    );
  }

  async findAllPatientGroup(groupId: string): Promise<DiaryType[]> {
    const snap = await firstValueFrom(
      this.collectionPatientGroup(groupId).get(),
    );
    return snap.docs.map((doc) =>
      DiaryTypeRepository.fromSchema(doc.data(), doc.id),
    );
  }

  async findAllPatient(patientId: string): Promise<DiaryType[]> {
    const snap = await firstValueFrom(this.collectionPatient(patientId).get());
    return snap.docs.map((doc) =>
      DiaryTypeRepository.fromSchema(doc.data(), doc.id),
    );
  }

  async saveToDietitian(diaryType: DiaryType, dietitianId: string) {
    const schema = DiaryTypeRepository.toSchema(diaryType);
    return this.collectionDietitian(dietitianId)
      .doc(diaryType.id.toString())
      .set(schema);
  }

  async saveToPatientGroup(diaryType: DiaryType, patientGroupId: string) {
    const schema = DiaryTypeRepository.toSchema(diaryType);
    return this.collectionPatientGroup(patientGroupId)
      .doc(diaryType.id.toString())
      .set(schema);
  }

  async saveToPatient(diaryType: DiaryType, patientId: string) {
    const schema = DiaryTypeRepository.toSchema(diaryType);
    return this.collectionPatient(patientId)
      .doc(diaryType.id.toString())
      .set(schema);
  }

  async saveBatch(diaryTypes: DiaryType[], patientId: string): Promise<void> {
    const batch = this.firestore.firestore.batch();
    for (const diaryType of diaryTypes) {
      const schema = DiaryTypeRepository.toSchema(diaryType);
      const ref = this.collectionPatient(patientId).doc(
        diaryType.id.toString(),
      ).ref;
      batch.set(ref, schema);
    }
    await batch.commit();
  }
}
