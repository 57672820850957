import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  MessageTemplate,
  MessageTemplateType,
} from '../domain/message_template/message-template';

export interface MessageTemplateSchema {
  content: string;
  type: MessageTemplateType;
  withSign: boolean;
}

@Injectable()
export class MessageTemplateRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<MessageTemplateSchema>('message_templates', queryFn);
  }

  toSchema(template: MessageTemplate): MessageTemplateSchema {
    return <MessageTemplateSchema>{
      content: template.content,
      type: template.type,
      withSign: template.withSign,
    };
  }

  fromSchema(schema: MessageTemplateSchema, id: number): MessageTemplate {
    return MessageTemplate.create(
      {
        content: schema.content,
        type: schema.type,
        withSign: schema.withSign,
      },
      new UniqueEntityID(id),
    );
  }

  async loadAll(
    dietitianId: string,
    type: MessageTemplateType,
  ): Promise<MessageTemplate[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('type', '==', type.toString()),
      ).get(),
    );
    return snap.docs
      .map((doc) => this.fromSchema(doc.data(), Number(doc.id)))
      .sort(this.sortMessageTemplate);
  }

  async save(
    dietitianId: string,
    type: MessageTemplateType,
    template: MessageTemplate,
  ): Promise<MessageTemplate> {
    const templates = await this.loadAll(dietitianId, type);
    let id = 1;
    if (templates && templates.length > 0) {
      const max = templates
        .map((value) => value.id.toValue() as number)
        .reduce((acc, current) => (acc < current ? current : acc));
      id = max + 1;
    }
    const schema = this.toSchema(template);
    if (dietitianId) {
      await this.collection(dietitianId).doc(id.toString()).set(schema);
      return this.fromSchema(schema, id);
    } else {
      return Promise.reject("Modèle d'invitation non identifié");
    }
  }

  async update(
    dietitianId: string,
    template: MessageTemplate,
    id: number,
  ): Promise<MessageTemplate> {
    const schema = this.toSchema(template);
    if (dietitianId) {
      await this.collection(dietitianId).doc(id.toString()).set(schema);
      return this.fromSchema(schema, id);
    } else {
      return Promise.reject("Modèle d'invitation non identifié");
    }
  }

  private sortMessageTemplate(a: MessageTemplate, b: MessageTemplate): number {
    if (a.id.toValue() > b.id.toValue()) {
      return 1;
    } else if (a.id.toValue() < b.id.toValue()) {
      return -1;
    } else {
      return 0;
    }
  }

  async delete(dietitianId: string, template: MessageTemplate) {
    return this.collection(dietitianId).doc(template.id.toString()).delete();
  }
}
