import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DietitianId } from '../domain/dietitian';
import { PatientGroup } from '../domain/patient_group/patient_group';
import { PatientGroupNotFoundException } from '../domain/patient_group/patient_group_exceptions';

export interface PatientGroupSchema {
  dietitianId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  patientCount: number;
  activatedPatientCount: number;
  archivedPatientCount: number;
  encouragementCount: number;
}

@Injectable()
export class PatientGroupRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<PatientGroupSchema>(
      'patient_groups',
      queryFn,
    );
  }

  toSchema(patientGroup: PatientGroup): PatientGroupSchema {
    return <PatientGroupSchema>{
      dietitianId: patientGroup.dietitianId.id.toString(),
      name: patientGroup.name,
      createdAt:
        patientGroup.createdAt !== undefined
          ? Timestamp.fromDate(patientGroup.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      patientCount: patientGroup.patientCount ?? 0,
      activatedPatientCount: patientGroup.activatedPatientCount ?? 0,
      archivedPatientCount: patientGroup.archivedPatientCount ?? 0,
      encouragementCount: patientGroup.encouragementCount ?? 0,
    };
  }

  fromSchema(schema: PatientGroupSchema, id: string): PatientGroup {
    return PatientGroup.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        name: schema.name,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        patientCount: schema.patientCount ?? 0,
        activatedPatientCount: schema.activatedPatientCount ?? 0,
        archivedPatientCount: schema.archivedPatientCount ?? 0,
        encouragementCount: schema.encouragementCount ?? 0,
      },
      new UniqueEntityID(id),
    );
  }

  async load(patientGroupId: string): Promise<PatientGroup> {
    const data = await firstValueFrom(
      this.collection().doc(patientGroupId).get(),
    ).then((snap) => snap.data());
    if (!data) throw new PatientGroupNotFoundException();

    return this.fromSchema(data, patientGroupId);
  }

  async existsForDietitianIdAndName(
    dietitianId: string,
    name: string,
    patientGroupId?: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('dietitianId', '==', dietitianId).where('name', '==', name),
      ).get(),
    );

    return (
      snap.size !== 0 && snap.docs.some((doc) => doc.id !== patientGroupId)
    );
  }

  async findByDietitianId(dietitianId: string): Promise<PatientGroup[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('dietitianId', '==', dietitianId).orderBy('name'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(patientGroup: PatientGroup): Promise<PatientGroup> {
    const schema = this.toSchema(patientGroup);
    const ref = await this.collection().add(schema);
    return this.fromSchema(schema, ref.id);
  }

  async save(patientGroup: PatientGroup): Promise<PatientGroup> {
    const schema = this.toSchema(patientGroup);
    await this.collection()
      .doc(patientGroup.patientGroupId.id.toString())
      .set(schema);
    return this.fromSchema(schema, patientGroup.patientGroupId.id.toString());
  }

  delete(patientGroupId: string): Promise<void> {
    return this.collection().doc(patientGroupId).delete();
  }
}
