import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface DiaryTypeProps {
  name: string;
  label: string;
  icon: string;
  hint: string;
  displayed: boolean;
  sorter: number;
}

export class DiaryTypeId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): DiaryTypeId {
    return new DiaryTypeId(id);
  }
}

export class DiaryType extends Entity<DiaryTypeProps> {
  get name(): string {
    return this.props.name;
  }

  get label(): string {
    return this.props.label;
  }

  set label(value: string) {
    this.props.label = value;
  }

  get icon(): string {
    return this.props.icon;
  }

  get hint(): string {
    return this.props.hint;
  }

  set hint(value: string) {
    this.props.hint = value;
  }

  get displayed(): boolean {
    return this.props.displayed;
  }

  set displayed(value: boolean) {
    this.props.displayed = value;
  }

  get sorter(): number {
    return this.props.sorter;
  }

  private constructor(props: DiaryTypeProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: DiaryTypeProps): DiaryType {
    return DiaryType.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: DiaryTypeProps, id?: UniqueEntityID): DiaryType {
    return new DiaryType(props, id);
  }
}
