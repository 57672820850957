import { Injectable } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LoadingStatus } from 'src/app/core/domain/events/state_provider';

import { AuthenticationCommands } from '../../auth/domain/authentication_commands';
import { LoggerRepository } from '../../logger/repositories/logger_repository';
import { MsdpGateway } from '../../msdp/gateway/msdp-gateway';
import {
  PatientActivated,
  PatientArchived,
  PatientCreated,
} from '../../patient/domain/events/patient.events';
import { PatientEventProvider } from '../../patient/domain/events/patient_event_provider';
import {
  PatientSelectionActivated,
  PatientSelectionArchived,
} from '../../patient/domain/events/patient_selection_event';
import { PatientSelectionEventProvider } from '../../patient/domain/events/patient_selection_event_provider';
import { DietitianProfileRepository } from '../repositories/dietitian_profile_repository';
import { DietitianRepository } from '../repositories/dietitian_repository';
import { PictureRepository } from '../repositories/picture_repository';
import { SubscriptionRepository } from '../repositories/subscription_repository';
import { Dietitian, DietitianProps } from './dietitian';
import { DietitianNotFoundException } from './dietitian_exceptions';
import {
  DietitianState,
  DietitianStateProvider,
} from './dietitian_state_provider';
import { DietitianEventProvider } from './events/dietitian_event_provider';
import {
  DietitianCreated,
  DietitianSignedIn,
  DietitianSignedOut,
  DietitianUpdated,
} from './events/dietitian_events';
import { EncouragementEventProvider } from './events/encouragement/encouragement_event_provider';
import {
  EncouragementBatchDeleted,
  EncouragementCreated,
  EncouragementDeleted,
} from './events/encouragement/encouragement_events';
import { Picture } from './picture/picture';
import {
  Subscription,
  SubscriptionProps,
  SubscriptionStatus,
} from './subscription/subscription';

export const SUBSCRIPTION_FREE_TRIAL_DAYS = 15;

@Injectable()
export class DietitianCommands {
  constructor(
    private repository: DietitianRepository,
    private dietitianProfileRepository: DietitianProfileRepository,
    private subscriptionRepository: SubscriptionRepository,
    private loggerRepository: LoggerRepository,
    private pictureRepository: PictureRepository,
    private stateProvider: DietitianStateProvider,
    private eventProvider: DietitianEventProvider,
    private patientEventProvider: PatientEventProvider,
    private patientSelectionEventProvider: PatientSelectionEventProvider,
    private encouragementEventProvider: EncouragementEventProvider,
    private authenticationCommands: AuthenticationCommands,
    private msdpGateway: MsdpGateway,
    private toastr: ToastrService,
  ) {
    this.patientEventProvider.events$.subscribe((event) => {
      if (event instanceof PatientCreated) {
        this.onPatientCreated(event).then();
      } else if (
        event instanceof PatientActivated ||
        event instanceof PatientArchived
      ) {
        this.onPatientActivatedOrArchived(event).then();
      }
    });

    this.encouragementEventProvider.events$.subscribe((event) => {
      if (
        event instanceof EncouragementCreated ||
        event instanceof EncouragementDeleted ||
        event instanceof EncouragementBatchDeleted
      ) {
        this.onEncouragementCreatedOrDeleted(event).then();
      }
    });
  }

  async getCurrentDietitian(): Promise<Dietitian | undefined> {
    const user = await this.authenticationCommands.getCurrentUser();
    if (user == null) {
      return undefined;
    }
    return this.repository.load(user.userId.id.toString());
  }

  async loadCurrentDietitian(): Promise<Dietitian | undefined> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      origin: 'DietitianCommands.loadCurrentDietitian',
    });
    try {
      const user = await this.authenticationCommands.loadCurrentUser();
      if (user == null || user.email == null) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.COMPLETE,
          origin: 'DietitianCommands.loadCurrentDietitian',
        });
        return undefined;
      }
      this.loggerRepository.who = user.id.toString();
      const dietitian = await this.repository.load(user.userId.id.toString());
      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        origin: 'DietitianCommands.loadCurrentDietitian',
        entity: dietitian,
      });

      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          origin: 'DietitianCommands.loadCurrentDietitian',
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signIn(email: string, password: string): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      origin: 'DietitianCommands.signin',
    });
    try {
      const user = await this.authenticationCommands.signIn(email, password);
      this.loggerRepository.who = user.id.toString();
      const dietitian = await this.repository.load(user.userId.id.toString());
      try {
        await this.msdpGateway.login(dietitian);
      } catch (e) {
        console.error(e);
      }
      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        origin: 'DietitianCommands.signin',
        entity: dietitian,
      });

      this.eventProvider.dispatch(new DietitianSignedIn(dietitian));
      return dietitian;
    } catch (error: any) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          origin: 'DietitianCommands.signin',
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signInWithMasterPassword(
    email: string,
    masterPassword: string,
  ): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
    });
    try {
      const user = await this.authenticationCommands.signInWithMasterPassword(
        email,
        masterPassword,
      );
      this.loggerRepository.who = user.id.toString();
      const dietitian = await this.repository.load(user.userId.id.toString());
      try {
        await this.msdpGateway.login(dietitian);
      } catch (e) {
        console.log(e);
      }
      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        entity: dietitian,
      });

      this.eventProvider.dispatch(new DietitianSignedIn(dietitian));
      return dietitian;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signUp(
    dietitianProps: DietitianProps,
    password: string,
  ): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      origin: 'DietitianCommands.signUp',
    });
    try {
      // signup user
      const user = await this.authenticationCommands.signUp(
        dietitianProps.email,
        password,
      );

      // create dietitian
      let dietitian = await this.saveDietitian(
        Dietitian.create(
          {
            ...dietitianProps,
            patientCount: 0,
            archivedPatientCount: 0,
            activatedPatientCount: 0,
            userId: user.userId,
          },
          user.userId.id,
        ),
      );

      // setup Stripe customer
      await this.repository.setupDietitianCustomer();

      // create trial subscription
      const nowDate = moment();
      const subscription = Subscription.create({
        status: SubscriptionStatus.Trialing,
        dietitianId: dietitian.dietitianId,
        createdAt: nowDate.toDate(),
        periodStart: nowDate.toDate(),
        periodEnd: nowDate
          .clone()
          .add(SUBSCRIPTION_FREE_TRIAL_DAYS, 'days')
          .toDate(),
      } as SubscriptionProps);
      await this.subscriptionRepository.createSubscription(subscription);

      // reload dietitian
      dietitian = await this.repository.load(
        dietitian.dietitianId.id.toString(),
      );

      this.loggerRepository.who = dietitian.id.toString();

      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        origin: 'DietitianCommands.signUp',
        entity: dietitian,
      });
      this.eventProvider.dispatch(new DietitianCreated(dietitian));
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          origin: 'DietitianCommands.signUp',
          message: error.message,
        });
      }
      throw error;
    }
  }

  async updateCurrentDietitian(
    dietitianProps: DietitianProps,
    notify = true,
  ): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      origin: 'DietitianCommands.updateCurrentDietitian',
      entity: this.stateProvider.state.entity,
    });
    let dietitian: Dietitian | undefined;
    try {
      const oldDietitian = await this.getCurrentDietitian();
      if (oldDietitian === undefined) {
        throw new DietitianNotFoundException();
      }
      dietitian = oldDietitian.copyWith(dietitianProps);
      await this.update(dietitian);
      if (notify) {
        this.toastr.success('Compte mis à jour');
      }
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          origin: 'DietitianCommands.updateCurrentDietitian',
          entity: dietitian,
        });
      }
      throw error;
    }
  }

  async saveDietitian(dietitian: Dietitian): Promise<Dietitian> {
    const savedDietitian = await this.repository.save(dietitian);
    try {
      await this.dietitianProfileRepository.save(savedDietitian);
    } catch (error) {
      // ignore exceptions
    }
    return savedDietitian;
  }

  async update(dietitian: Dietitian, notify = true): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      entity: this.stateProvider.state.entity,
    });
    try {
      let oldDietitian = await this.repository.load(dietitian.id.toString());
      if (oldDietitian == null) throw new DietitianNotFoundException();
      if (!oldDietitian.picture?.equals(dietitian.picture)) {
        if (oldDietitian.picture != null) {
          await this.pictureRepository.delete(oldDietitian.picture);
          oldDietitian = oldDietitian.updatePicture(null);
          await this.saveDietitian(oldDietitian);
        }
        if (dietitian.picture != null) {
          const newPicture = await this.pictureRepository.save(
            dietitian.picture,
          );
          dietitian = dietitian.updatePicture(newPicture);
        }
      }
      if (dietitian.email != oldDietitian.email) {
        await this.authenticationCommands.updateCurrentUserEmail(
          dietitian.email,
        );
      }
      await this.saveDietitian(dietitian);
      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        entity: dietitian,
      });
      if (notify) {
        this.eventProvider.dispatch(new DietitianUpdated(dietitian));
      }
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: dietitian,
        });
      }
      throw error;
    }
  }

  async updateCurrentDietitianPassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      origin: 'DietitianCommands.updateCurrentDietitianPassword',
      entity: this.stateProvider.state.entity,
    });
    let dietitian: Dietitian | undefined;
    try {
      dietitian = await this.getCurrentDietitian();
      if (dietitian == null) throw new DietitianNotFoundException();
      await this.authenticationCommands.updateCurrentUserPassword(
        oldPassword,
        newPassword,
      );
      this.stateProvider.setState(<DietitianState>{
        loading: LoadingStatus.COMPLETE,
        origin: 'DietitianCommands.updateCurrentDietitianPassword',
        entity: dietitian,
      });
      this.eventProvider.dispatch(new DietitianUpdated(dietitian));
      this.toastr.success('Mot de passe mis à jour');
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          origin: 'DietitianCommands.updateCurrentDietitianPassword',
          entity: dietitian,
        });
      }
      throw error;
    }
  }

  async updateCurrentDietitianPicture(file: File): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      entity: this.stateProvider.state.entity,
    });
    let dietitian: Dietitian | undefined;
    try {
      dietitian = await this.getCurrentDietitian();
      if (dietitian == null) throw new DietitianNotFoundException();
      dietitian = dietitian.updatePicture(
        Picture.create({
          file: file,
          path: `/users/${dietitian.userId.id.toString()}/picture`,
        }),
      );
      await this.update(dietitian);
      this.toastr.success('Photo mise à jour');
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: dietitian,
        });
      }
      throw error;
    }
  }

  async deleteCurrentDietitianPicture(): Promise<Dietitian> {
    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.LOADING,
      entity: this.stateProvider.state.entity,
    });
    let dietitian: Dietitian | undefined;
    try {
      dietitian = await this.getCurrentDietitian();
      if (dietitian == null) throw new DietitianNotFoundException();
      dietitian = dietitian.updatePicture(null);
      await this.update(dietitian);
      this.toastr.success('Photo supprimée');
      return dietitian;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<DietitianState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: dietitian,
        });
      }
      throw error;
    }
  }

  async signOut() {
    let dietitian: Dietitian | undefined;
    try {
      dietitian = await this.getCurrentDietitian();
      // eslint-disable-next-line no-empty
    } catch (error: unknown) {}
    if (dietitian != null) {
      dietitian = dietitian?.copyWith({
        notificationToken: undefined,
      } as DietitianProps);
      await this.update(dietitian, false);

      await this.msdpGateway.logout(dietitian);

      this.eventProvider.dispatch(new DietitianSignedOut(dietitian));
    }

    await this.authenticationCommands.signOut();

    this.stateProvider.setState(<DietitianState>{
      loading: LoadingStatus.COMPLETE,
      origin: 'DietitianCommands.signOut',
    });
  }

  async resetPassword(email: string, redirection: string) {
    if (!(await this.repository.existsForEmail(email))) {
      throw new DietitianNotFoundException();
    }
    await this.authenticationCommands.sendPasswordResetEmail(
      email,
      redirection,
    );
    this.toastr.success('Email de réinitialisation de mot de passe envoyé');
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    await this.authenticationCommands.confirmPasswordReset(code, newPassword);
    this.toastr.success('Mot de passe mis à jour');
  }

  async onPatientCreated(event: PatientCreated) {
    // check access
    const dietitianId =
      this.stateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    // check patient relation
    if (event.entity.dietId != dietitianId) return;

    const dietitian = await this.getCurrentDietitian();
    if (!dietitian) return;

    // update dietitian patient counts
    await this.updateCurrentDietitian(
      dietitian.updatePatientCount(
        dietitian.patientCount + 1,
        Math.max(
          dietitian.activatedPatientCount + (event.entity.archived ? 0 : 1),
          0,
        ),
        Math.max(
          dietitian.archivedPatientCount + (event.entity.archived ? 1 : 0),
          0,
        ),
      ).props,
    );
  }

  async onPatientActivatedOrArchived(
    event: PatientActivated | PatientArchived,
  ) {
    // check access
    const dietitianId =
      this.stateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    // check patient relation
    if (event.entity.dietId != dietitianId) return;

    const dietitian = await this.getCurrentDietitian();
    if (!dietitian) return;

    // update dietitian patient counts
    await this.updateCurrentDietitian(
      dietitian.updatePatientCount(
        dietitian.patientCount,
        Math.max(
          dietitian.activatedPatientCount + (event.entity.archived ? -1 : 1),
          0,
        ),
        Math.max(
          dietitian.archivedPatientCount + (event.entity.archived ? 1 : -1),
          0,
        ),
      ).props,
    );
  }

  async onEncouragementCreatedOrDeleted(
    event:
      | EncouragementCreated
      | EncouragementDeleted
      | EncouragementBatchDeleted,
  ) {
    if (!event.entity.scheduled) return;

    // check access
    const dietitianId = this.stateProvider.state.entity?.dietitianId;
    if (!dietitianId) return;

    // check relation
    if (!event?.entity?.dietitianId?.equals(dietitianId)) return;

    const dietitian = await this.getCurrentDietitian();

    if (!dietitian) return;

    let countChange = 0;
    if (event instanceof EncouragementCreated) {
      countChange++;
    } else if (event instanceof EncouragementDeleted) {
      countChange--;
    } else if (event instanceof EncouragementBatchDeleted) {
      countChange -= event.encouragements.filter((e) => e.scheduled).length;
    }

    // update encouragement count
    await this.updateCurrentDietitian(
      dietitian.copyWith({
        encouragementCount: dietitian.encouragementCount + countChange,
      } as DietitianProps).props,
    );
  }

  async updateCurrentDietitianLastNews(): Promise<Dietitian> {
    const oldDietitian = await this.getCurrentDietitian();
    if (oldDietitian === undefined) {
      throw new DietitianNotFoundException();
    }
    const dietitian = oldDietitian.copyWith({
      lastNews: new Date(),
    } as DietitianProps);
    await this.update(dietitian);
    return dietitian;
  }

  public async onPatientSelectionArchived(
    event: PatientSelectionArchived,
  ): Promise<void> {
    const dietitianId =
      this.stateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    const dietitian = await this.getCurrentDietitian();
    if (!dietitian) return;

    let count = 0;
    for (const patient of event.items) {
      if (patient.dietId === dietitianId) {
        count++;
      }
    }
    await this.updateCurrentDietitian(
      dietitian.updatePatientCount(
        dietitian.patientCount,
        Math.max(dietitian.activatedPatientCount - count, 0),
        Math.max(dietitian.archivedPatientCount + count, 0),
      ).props,
    );
  }

  public async onPatientSelectionActivated(
    event: PatientSelectionActivated,
  ): Promise<void> {
    const dietitianId =
      this.stateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    const dietitian = await this.getCurrentDietitian();
    if (!dietitian) return;

    let count = 0;
    for (const patient of event.items) {
      if (patient.dietId === dietitianId) {
        count++;
      }
    }
    await this.updateCurrentDietitian(
      dietitian.updatePatientCount(
        dietitian.patientCount,
        Math.max(dietitian.activatedPatientCount + count, 0),
        Math.max(dietitian.archivedPatientCount - count, 0),
      ).props,
    );
  }
}
