import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom, Observable } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionComposition } from '../domain/nutritions/nutrition-composition';
import { NutritionIngredientNotFoundException } from '../domain/nutritions/nutrition-exceptions';
import { NutritionIngredient } from '../domain/nutritions/nutrition-ingredient';
import {
  CiqualCompositionRepository,
  SimpleCiqualCompositionSchema,
} from './ciqual_composition.repository';

export interface CiqualIngredientSchema {
  label: string;
  group: string;
  ssGroup: string;
  ssSsGroup: string;
  middle?: boolean;
  compositions?: SimpleCiqualCompositionSchema[];
}

@Injectable()
export class CiqualIngredientRepository {
  private readonly searchIngredientFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }[]>;

  private static readonly CIQUAL_INDEX = 'ciqual_ingredients';

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
    private ciqualCompositionRepository: CiqualCompositionRepository,
  ) {
    this.searchIngredientFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }[]
    >('ciqual-searchIngredients');
  }

  private collection(qry?: QueryFn) {
    return this.firestore.collection<CiqualIngredientSchema>(
      'ciqual_alims',
      qry,
    );
  }

  fromSchema(schema: CiqualIngredientSchema, id: string): NutritionIngredient {
    return NutritionIngredient.create(
      {
        label: schema.label,
        group: schema.group,
        ssGroup: schema.ssGroup,
        ssSsGroup: schema.ssSsGroup,
        middle: schema.middle ? schema.middle : false,
        compositions: schema.compositions
          ? (schema.compositions
              .map((s) => this.ciqualCompositionRepository.fromSimpleSchema(s))
              .filter(
                (c) => c && c.component !== undefined,
              ) as NutritionComposition[])
          : [],
      },
      new UniqueEntityID(id),
    );
  }

  async load(ingredientId: string): Promise<NutritionIngredient> {
    const snap = await firstValueFrom(
      this.collection().doc(ingredientId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new NutritionIngredientNotFoundException();
    }
    return this.fromSchema(snap.data() as CiqualIngredientSchema, snap.id);
  }

  async findAllMiddle(): Promise<NutritionIngredient[]> {
    const snap = await firstValueFrom(
      this.collection((ref) => ref.where('middle', '==', true)).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findAllByGroup(groupCode: string): Promise<NutritionIngredient[]> {
    if (groupCode.length === 2) {
      const snap = await firstValueFrom(
        this.collection((ref) => {
          let query = ref.where('group', '==', groupCode);
          query = query.where('ssGroup', '==', '0000');
          query = query.where('ssSsGroup', '==', '000000');
          query = query.where('middle', '!=', true);
          return query;
        }).get(),
      );
      return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
    } else if (groupCode.length === 4) {
      const snap = await firstValueFrom(
        this.collection((ref) => {
          let query = ref.where('group', '==', groupCode.substring(0, 2));
          query = query.where('ssGroup', '==', groupCode);
          query = query.where('ssSsGroup', '==', '000000');
          query = query.where('middle', '!=', true);
          return query;
        }).get(),
      );
      return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
    } else if (groupCode.length === 6) {
      const snap = await firstValueFrom(
        this.collection((ref) => {
          let query = ref.where('group', '==', groupCode.substring(0, 2));
          query = query.where('ssGroup', '==', groupCode.substring(0, 4));
          query = query.where('ssSsGroup', '==', groupCode);
          query = query.where('middle', '!=', true);
          return query;
        }).get(),
      );
      return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
    } else {
      return Promise.resolve([]);
    }
  }

  async searchIngredients(query: string): Promise<NutritionIngredient[]> {
    const index = CiqualIngredientRepository.CIQUAL_INDEX;
    const results = await firstValueFrom(
      this.searchIngredientFromCloud({
        query,
        index,
      }),
    );
    return results.map(this.fromMap);
  }

  fromMap(map: { [key: string]: unknown }): NutritionIngredient {
    return NutritionIngredient.create(
      {
        label: map['label'] as string,
        group: map['group'] as string,
        ssGroup: map['ssGroup'] as string,
        ssSsGroup: map['ssSsGroup'] as string,
        compositions: [],
      },
      new UniqueEntityID(map['objectID'] as string),
    );
  }
}
