import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NutritionComponent } from '../../data/dietitian/domain/nutritions/nutrition-component';
import { NutritionIngredient } from '../../data/dietitian/domain/nutritions/nutrition-ingredient';
import { CiqualCompositionRepository } from '../../data/dietitian/repositories/ciqual_composition.repository';
import { CiqualIngredientRepository } from '../../data/dietitian/repositories/ciqual_ingredient.repository';
import { NutritionIngredientRepository } from '../../data/dietitian/repositories/nutrition-ingredient.repository';

@Injectable()
export class NutritionService {
  private _nutritionId: string | null;

  public get nutritionId(): string | null {
    return this._nutritionId;
  }

  public set nutritionId(value: string | null) {
    this._nutritionId = value;
  }

  private ingredientDictionary: NutritionIngredient[];

  get components(): NutritionComponent[] {
    return this.ciqualCompositionRepository.components;
  }

  constructor(
    private router: Router,
    private ciqualCompositionRepository: CiqualCompositionRepository,
    private ciqualIngredientRepository: CiqualIngredientRepository,
    private nutritionIngredientRepository: NutritionIngredientRepository,
  ) {
    // NaDa
  }

  async initIngredientDictionary(dietitianId: string): Promise<void> {
    if (!this.ingredientDictionary || this.ingredientDictionary.length === 0) {
      this.ingredientDictionary =
        await this.nutritionIngredientRepository.findAll(dietitianId);
    }
  }

  getRenamedIngredient(ingredient: NutritionIngredient): NutritionIngredient {
    if (this.ingredientDictionary) {
      const result = this.ingredientDictionary.filter(
        (ing) => ing.code === ingredient.code,
      );
      if (result.length > 0) {
        ingredient.label = result[0].label;
      }
    }
    return ingredient;
  }

  back(backTarget: 'list' | 'archives' | undefined): void {
    if (backTarget) {
      this.router.navigate(['dashboard', 'nutritions', backTarget]).then();
    } else {
      this.router.navigate(['dashboard', 'nutritions']).then();
    }
  }

  updateIngredientDictionary(ingredient: NutritionIngredient) {
    const expected = this.ingredientDictionary.filter(
      (ing) => ing.code === ingredient.code,
    );
    if (expected && expected.length > 0) {
      expected[0].label = ingredient.label;
    } else {
      this.ingredientDictionary.push(ingredient);
    }
  }

  async loadCompositions(
    ingredient: NutritionIngredient,
    dietitianId: string | undefined,
  ) {
    try {
      const selectedIngredient = await this.ciqualIngredientRepository.load(
        ingredient.code,
      );
      ingredient.compositions = selectedIngredient.compositions;
    } catch (e) {
      if (dietitianId) {
        const selectedIngredient =
          await this.nutritionIngredientRepository.load(
            dietitianId,
            ingredient.code,
          );
        if (selectedIngredient) {
          ingredient.compositions = selectedIngredient.compositions;
        }
      }
    }
  }
}
