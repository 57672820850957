import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { PatientId } from 'src/app/data/patient/domain/patient';
import { TrackerType } from 'src/app/data/tracker/domain/tracker/tracker_type';

import { DietitianId } from '../dietitian';

export interface CustomTrackerProps {
  dietitianId: DietitianId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  diaryType: string;
  type: TrackerType;
  max: number;
}

export class CustomTrackerId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): CustomTrackerId {
    return new CustomTrackerId(id);
  }
}

export class CustomTracker extends Entity<CustomTrackerProps> {
  get customTrackerId(): CustomTrackerId {
    return CustomTrackerId.create(this.id);
  }

  get dietitianId(): PatientId {
    return this.props.dietitianId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get name(): string {
    return this.props.name;
  }

  get diaryType(): string {
    return this.props.diaryType;
  }

  get type(): TrackerType {
    return this.props.type;
  }

  get max(): number {
    return this.props.max;
  }

  private constructor(props: CustomTrackerProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: CustomTrackerProps): CustomTracker {
    return CustomTracker.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: CustomTrackerProps,
    id?: UniqueEntityID,
  ): CustomTracker {
    return new CustomTracker(props, id);
  }
}
