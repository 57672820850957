import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionIngredient } from './nutrition-ingredient';
import { NutritionMeal } from './nutrition-meal';
import { NutritionQuantityUnit } from './nutrition-quantity-unit';

export enum NV_CODE {
  ENERGY_UE_C = '328',
  ENERGY_UE_C_S = '_328_',
  ENERGY_JONES_C = '333',
  ENERGY_UE_J = '327',
  ENERGY_JONES_J = '332',
  PROTEIN_UE = '25003',
  PROTEIN_JONES = '25000',
  LIPID = '40000',
  GLUCID = '31000',
  FIBER = '34100',
  POLYOL = '34000',
  ALCOOL = '60000',
  ACID_ORGANIC = '65000',
}

export interface NutritionValue {
  code: string;
  value: number;
}

export function calculateEnergyFrom(
  proteins: number | undefined,
  lipids: number | undefined,
  glucids: number | undefined,
): number | undefined {
  if (proteins || lipids || glucids) {
    let energy = 0;
    if (proteins) {
      energy += proteins * 4;
    }
    if (lipids) {
      energy += lipids * 9;
    }
    if (glucids) {
      energy += glucids * 4;
    }
    return energy;
  } else {
    return undefined;
  }
}

export function energy(meal: NutritionMeal): number {
  let energy = 0;
  for (const foodstuff of meal.foodstuffs) {
    const energyUe = getNutritionRawValue(foodstuff, NV_CODE.ENERGY_UE_C_S);
    if (energyUe > 0) {
      energy += energyUe;
    } else {
      const proteins = getNutritionRawValue(foodstuff, NV_CODE.PROTEIN_UE);
      const lipids = getNutritionRawValue(foodstuff, NV_CODE.LIPID);
      const glucids = getNutritionRawValue(foodstuff, NV_CODE.GLUCID);
      energy += calculateEnergyFrom(proteins, lipids, glucids) ?? 0;
    }
  }
  return energy;
}

function getNutritionRawValue(
  foodstuff: NutritionFoodstuff,
  code: string,
): number {
  let result = 0;
  if (foodstuff) {
    result = foodstuff.getNutritionValues(code).find(code)?.value ?? 0;
  }
  return result;
}

export class NutritionValues {
  values: NutritionValue[] = [];

  insert(value: NutritionValue | undefined) {
    if (value) {
      this.values.push(value);
    }
  }

  find(code: string): NutritionValue | undefined {
    if (code === NV_CODE.ENERGY_UE_C) {
      let result =
        this.values.filter((v) => v.code === NV_CODE.ENERGY_UE_C_S)[0] ??
        undefined;
      if (result === undefined) {
        result = { code, value: 0 };
        const proteins = this.values.filter(
          (v) => v.code === NV_CODE.PROTEIN_UE,
        );
        const proteinsValue =
          proteins.length > 0
            ? proteins[0].value && !isNaN(proteins[0].value)
              ? proteins[0].value
              : 0
            : 0;
        const lipids = this.values.filter((v) => v.code === NV_CODE.LIPID);
        const lipidsValue =
          lipids.length > 0
            ? lipids[0].value && !isNaN(lipids[0].value)
              ? lipids[0].value
              : 0
            : 0;
        const glucids = this.values.filter((v) => v.code === NV_CODE.GLUCID);
        const glucidsValue =
          glucids.length > 0
            ? glucids[0].value && !isNaN(glucids[0].value)
              ? glucids[0].value
              : 0
            : 0;
        result.value =
          calculateEnergyFrom(proteinsValue, lipidsValue, glucidsValue) ?? 0;
      }
      return result;
    } else {
      return this.values.filter((v) => v.code === code)[0] ?? undefined;
    }
  }
}

export interface NutritionFoodstuffProps {
  ingredient: NutritionIngredient;
  quantity: number;
  quantityUnit: NutritionQuantityUnit;
  labelWithoutQuantity?: string;
  sorter: number;
  createdAt?: Date;
  updatedAt?: Date;
  alternatives: NutritionFoodstuff[];
}

export class NutritionFoodstuffId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionFoodstuffId {
    return new NutritionFoodstuffId(id);
  }
}

export class NutritionFoodstuff extends Entity<NutritionFoodstuffProps> {
  private constructor(props: NutritionFoodstuffProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get nutritionFoodstuffId(): NutritionFoodstuffId {
    return NutritionFoodstuffId.create(this.id);
  }

  get ingredient(): NutritionIngredient {
    return this.props.ingredient;
  }

  set ingredient(v: NutritionIngredient) {
    this.props.ingredient = v;
  }

  get quantity(): number {
    return this.props.quantity;
  }

  set quantity(value: number) {
    this.props.quantity = value;
  }

  get quantityUnit(): NutritionQuantityUnit {
    return this.props.quantityUnit;
  }

  set quantityUnit(value: NutritionQuantityUnit) {
    this.props.quantityUnit = value;
  }

  get labelWithoutQuantity(): string | undefined {
    return this.props.labelWithoutQuantity;
  }

  set labelWithoutQuantity(value: string | undefined) {
    this.props.labelWithoutQuantity = value;
  }

  get sorter(): number {
    return this.props.sorter;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get alternatives(): NutritionFoodstuff[] {
    return this.props.alternatives;
  }

  set alternatives(value: NutritionFoodstuff[]) {
    this.props.alternatives = value;
  }

  get weight(): number | undefined {
    if (this.quantityUnit) {
      return this.quantity * this.quantityUnit.weight;
    } else {
      return undefined;
    }
  }

  getNutritionValues(code?: string | undefined): NutritionValues {
    const result = new NutritionValues();
    if (
      this.ingredient &&
      this.ingredient.compositions &&
      this.ingredient.compositions.length > 0
    ) {
      if (code) {
        result.insert(this.nutritionValue(code));
      } else {
        result.insert(this.nutritionValue(NV_CODE.ENERGY_UE_C_S));
        result.insert(this.nutritionValue(NV_CODE.PROTEIN_UE));
        result.insert(this.nutritionValue(NV_CODE.LIPID));
        result.insert(this.nutritionValue(NV_CODE.GLUCID));
      }
    }
    return result;
  }

  private nutritionValue(code: string): NutritionValue | undefined {
    const composition = this.ingredient.compositions.filter(
      (c) => c.component?.nutritionComponentId.id.toString() === code,
    );
    if (composition && composition.length > 0) {
      if (composition[0].value && typeof composition[0].value === 'number') {
        return {
          code: code,
          value: this.weight ? (composition[0].value * this.weight) / 100 : 0,
        };
      }
    }
    return undefined;
  }

  public static create(
    props: NutritionFoodstuffProps,
    id?: UniqueEntityID,
  ): NutritionFoodstuff {
    return new NutritionFoodstuff(props, id);
  }

  copyWith(props: NutritionFoodstuffProps): NutritionFoodstuff {
    return NutritionFoodstuff.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }
}
