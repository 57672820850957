import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { EmailModule } from '../email/email.module';
import { DiaryCommands } from '../tracker/domain/diary/diary_commands';
import { DiaryTypeCommands } from '../tracker/domain/diary_type/diary_type_commands';
import { DiaryEventProvider } from '../tracker/domain/events/diary/diary_event_provider';
import { TrackerEventProvider } from '../tracker/domain/events/tracker/tracker_event_provider';
import { TrackerCommands } from '../tracker/domain/tracker/tracker_commands';
import { DiaryTypeRepository } from '../tracker/repositories/diary_type_repository';
import { TrackerRepository } from '../tracker/repositories/tracker_repository';
import { CustomNutritionCommands } from './domain/custom-nutrition/custom-nutrition-commands';
import { EncouragementCommands } from './domain/encouragement/encouragement_commands';
import { PatientEventProvider } from './domain/events/patient_event_provider';
import { PatientSelectionEventProvider } from './domain/events/patient_selection_event_provider';
import { PatientSerieEventProvider } from './domain/events/patient_serie/patient_serie_event_provider';
import { PatientCommands } from './domain/patient_commands';
import { PatientSerieCommands } from './domain/patient_serie/patient_serie_commands';
import { PatientStateProvider } from './domain/patient_state_provider';
import { CustomNutritionRepository } from './repositories/custom-nutrition.repository';
import { EncouragementRepository } from './repositories/encouragement_repository';
import { PatientRepository } from './repositories/patient_repository';
import { PatientSerieRepository } from './repositories/patient_serie_repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, EmailModule],
  providers: [
    EncouragementRepository,
    PatientRepository,
    PatientSerieRepository,
    TrackerRepository,
    PatientStateProvider,
    PatientEventProvider,
    PatientSerieEventProvider,
    DiaryEventProvider,
    PatientCommands,
    PatientSerieCommands,
    DiaryCommands,
    EncouragementCommands,
    TrackerCommands,
    DiaryTypeRepository,
    DiaryTypeCommands,
    CustomNutritionRepository,
    CustomNutritionCommands,
  ],
})
export class PatientModule {
  static forRoot(): ModuleWithProviders<PatientModule> {
    return {
      ngModule: PatientModule,
      providers: [
        { provide: PatientStateProvider },
        { provide: PatientEventProvider },
        { provide: PatientSelectionEventProvider },
        { provide: PatientSerieEventProvider },
        { provide: DiaryEventProvider },
        { provide: TrackerEventProvider },
        { provide: PatientCommands },
        { provide: PatientSerieCommands },
        { provide: DiaryCommands },
        { provide: EncouragementCommands },
        { provide: TrackerCommands },
        { provide: CustomNutritionCommands },
        { provide: DiaryTypeCommands },
      ],
    };
  }
}
