import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionComponent } from '../domain/nutritions/nutrition-component';
import { NutritionComposition } from '../domain/nutritions/nutrition-composition';
import { NV_CODE } from '../domain/nutritions/nutrition-foodstuff';
import { CiqualComponentRepository } from './ciqual_component.repository';

export interface SimpleCiqualCompositionSchema {
  confiance: string;
  constant: string | null;
  teneur?: number;
  min?: number | null;
  max?: number | null;
}

export interface CiqualCompositionSchema extends SimpleCiqualCompositionSchema {
  alim: string;
  source: string | null;
  date: Timestamp | null;
}

@Injectable()
export class CiqualCompositionRepository {
  private _components: NutritionComponent[] = [];

  constructor(
    private firestore: AngularFirestore,
    private componentRepository: CiqualComponentRepository,
  ) {
    this.initComponents().then();
  }

  get components(): NutritionComponent[] {
    return this._components;
  }

  private async initComponents(): Promise<NutritionComponent[]> {
    if (this._components.length === 0) {
      this._components = (await this.componentRepository.findAll()).filter(
        (c) =>
          c.nutritionComponentId.id.toString() !== NV_CODE.PROTEIN_JONES &&
          c.nutritionComponentId.id.toString() !== NV_CODE.ENERGY_UE_J &&
          c.nutritionComponentId.id.toString() !== NV_CODE.ENERGY_JONES_C &&
          c.nutritionComponentId.id.toString() !== NV_CODE.ENERGY_JONES_J,
      );
    }
    return this._components;
  }

  private collection(query?: QueryFn) {
    return this.firestore.collection<CiqualCompositionSchema>(
      'ciqual_compos',
      query,
    );
  }

  private findComponent(componentId: string): NutritionComponent | undefined {
    return (
      this._components.filter(
        (c) => c.nutritionComponentId.id.toString() === componentId,
      )[0] ?? undefined
    );
  }

  toSchema(
    nutritionComposition: NutritionComposition,
  ): CiqualCompositionSchema {
    return <CiqualCompositionSchema>{
      confiance: nutritionComposition.confiance,
      date:
        nutritionComposition.date !== undefined
          ? Timestamp.fromDate(nutritionComposition.date)
          : Timestamp.now(),
      constant: nutritionComposition.component
        ? nutritionComposition.component.nutritionComponentId.id.toString()
        : null,
      teneur: nutritionComposition.value,
      min: nutritionComposition.min,
      max: nutritionComposition.max,
    };
  }

  fromSchema(
    schema: CiqualCompositionSchema,
    id: string,
  ): NutritionComposition {
    return NutritionComposition.create(
      {
        component: schema.constant
          ? this.findComponent(schema.constant)
          : undefined,
        date: schema.date ? schema.date.toDate() : undefined,
        confiance: schema.confiance,
        value: schema.teneur,
        min: schema.min ? schema.min : undefined,
        max: schema.max ? schema.max : undefined,
      },
      new UniqueEntityID(id),
    );
  }

  fromSimpleSchema(
    schema: SimpleCiqualCompositionSchema,
  ): NutritionComposition | undefined {
    const component = schema.constant
      ? this.findComponent(schema.constant)
      : undefined;
    if (component) {
      return NutritionComposition.create({
        component,
        date: undefined,
        confiance: schema.confiance,
        value: schema.teneur,
        min: schema.min ? schema.min : undefined,
        max: schema.max ? schema.max : undefined,
      });
    }
    return undefined;
  }
}
