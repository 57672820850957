import { Injectable } from '@angular/core';

import { DiaryTypeRepository } from '../../repositories/diary_type_repository';
import { DiaryType } from './diary_type';

@Injectable()
export class DiaryTypeCommands {
  constructor(private diaryTypeRepository: DiaryTypeRepository) {
    // Nada
  }

  async findAllDiaryTypes(
    dietitianId: string | undefined,
    groupId: string | undefined,
    patientId: string | undefined,
  ) {
    // Case Patient
    if (patientId) {
      let diaryTypes = await this.diaryTypeRepository.findAllPatient(patientId);
      if (!diaryTypes || diaryTypes.length === 0) {
        diaryTypes = await this.diaryTypeRepository.findAllDefault();
      }
      return diaryTypes;
    }
    // Case Patient Group
    else if (groupId) {
      let diaryTypes = await this.diaryTypeRepository.findAllPatientGroup(
        groupId,
      );
      if (!diaryTypes || diaryTypes.length === 0) {
        diaryTypes = await this.diaryTypeRepository.findAllDefault();
      }
      return diaryTypes;
    }
    // Case Dietitian
    else if (dietitianId) {
      let diaryTypes = await this.diaryTypeRepository.findAllDietitian(
        dietitianId,
      );
      if (!diaryTypes || diaryTypes.length === 0) {
        diaryTypes = await this.diaryTypeRepository.findAllDefault();
      }
      return diaryTypes;
    }
    // Case Default
    else {
      return this.diaryTypeRepository.findAllDefault();
    }
  }

  async saveAllDiaryTypeToGroup(
    diaryTypes: DiaryType[],
    patientGroupId: string,
  ) {
    for (const event of diaryTypes) {
      await this.diaryTypeRepository.saveToPatientGroup(event, patientGroupId);
    }
  }

  async saveDiaryType(
    diaryType: DiaryType,
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
  ) {
    console.log('☢️ saveDiaryType', {
      diaryType,
      dietitianId,
      patientGroupId,
      patientId,
    });
    const events = await this.findAllDiaryTypes(
      dietitianId,
      patientGroupId,
      patientId,
    );
    console.log('☢️ events', events);
    for (const event of events) {
      if (event.name === diaryType.name) {
        event.label = diaryType.label;
        event.hint = diaryType.hint;
        event.displayed = diaryType.displayed;
      }
      if (patientId) {
        await this.diaryTypeRepository.saveToPatient(event, patientId);
      } else if (patientGroupId) {
        await this.diaryTypeRepository.saveToPatientGroup(
          event,
          patientGroupId,
        );
      } else if (dietitianId) {
        await this.diaryTypeRepository.saveToDietitian(event, dietitianId);
      }
    }
  }

  async createAllDiaryTypeToPatient(
    diaryTypes: DiaryType[],
    patientId: string,
  ) {
    await this.diaryTypeRepository.saveBatch(diaryTypes, patientId);
  }
}
